










import { defineComponent } from "@vue/composition-api";
import InfoElement from "./InfoElement.vue";
import { InfoElementType } from "../constants/info-element-type";
import { getStatuses } from "../utils/getStatuses";
import { mapActions } from "vuex";
import eventBus from "@monorepo/utils/src/eventBus";
import { SYNC_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/syncEvents";

export default defineComponent({
  components: {
    InfoElement,
  },
  data() {
    return {
      elements: {},
      InfoElementType,
    };
  },
  props: {
    refreshScroll: {
      type: Function,
      required: true,
    },
    addFilter: {
      type: Function,
      required: true,
    },
    fieldFilters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    graphKeys() {
      return Object.keys(InfoElementType);
    },
    isActive(): (key: string) => boolean {
      return (key: string) => {
        return this.fieldFilters.statusItem ? this.fieldFilters.statusItem.includes(key) : false;
      };
    },
  },
  methods: {
    ...mapActions("syncLogOikView", ["clearFilters"]),
    changeFilterStatus(
      this: { addFilter: (status: { status: string[]; statusItem: string; creationDateFrom: string }) => void; refreshScroll: () => void },
      status: string
    ) {
      eventBus.$emit(SYNC_EVENT_BUS_ACTIONS.CHANGE_STATUS, status);
      this.addFilter({
        statusItem: status,
        status: [...getStatuses(status)],
        creationDateFrom: `${new Date().getFullYear()}-01-01`,
      });
      this.refreshScroll();
    },
  },
});
