





















import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { InfoElementType } from "../constants/info-element-type";
import Chart from "./Chart.vue";

export default defineComponent({
  components: {
    Chart,
  },
  props: {
    type: {
      type: String,
      default: InfoElementType.DEFAULT,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    const data: { date: string; count: number }[] = await this.setSuccessGraphData(this.path);
    this.graphData = data || [];
  },
  data() {
    return {
      InfoElementType,
      graphData: [] as { date: string; count: number }[],
      options: {
        legend: {
          display: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              height: 0,
              ticks: {
                beginAtZero: true,
                fontSize: 0,
                display: false, //this will remove only the label
              },
              gridLines: {
                display: false,
                drawBorder: false,
                drawOnChartArea: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                padding: 0,
                display: false, //this will remove only the label
              },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters("syncLogOikView", ["isTableLoading"]),
    count(): number {
      return (this.graphData || []).reduce((result: number, item) => result + item.count, 0);
    },
    percent(): number {
      const items = this.graphData || [];
      if (!items.length || !items[items.length - 1].count) {
        return 0;
      }

      return (this.graphData || []).reduce((result: number, item) => result + item.count, 0) / items[items.length - 1].count;
    },
    currentYear(): number {
      return new Date().getFullYear();
    },
    graphBorderColor(): string {
      switch (this.type) {
        case InfoElementType.ERROR:
          return "211,64,57";
        case InfoElementType.SUCCESS:
          return "0,164,89";
        case InfoElementType.WAITING:
          return "36,98,209";
        default:
          return "118,118,122";
      }
    },
    path(): string {
      switch (this.type) {
        case InfoElementType.ERROR:
          return "error-refreshed";
        case InfoElementType.SUCCESS:
          return "success-refreshed";
        case InfoElementType.WAITING:
          return "wait-response";
        default:
          return "total-formed";
      }
    },
    datacollection(): { labels: string[]; datasets: { label: string; data: number[]; borderColor: string; backgroundColor: string }[] } {
      return {
        labels: (this.graphData || []).map((item) => item.date),
        datasets: [
          {
            label: "Dataset",
            data: (this.graphData || []).reduce(
              (result: number[], item) => result.concat((!result.length ? 0 : result[result.length - 1]) + item.count),
              []
            ),
            borderColor: `rgb(${this.graphBorderColor})`,
            backgroundColor: `rgba(${this.graphBorderColor},0.2)`,
          },
        ],
      };
    },
    contentInfo(): string {
      switch (this.type) {
        case InfoElementType.ERROR:
          return "С ошибкой";
        case InfoElementType.SUCCESS:
          return "Успешная синхронизация";
        case InfoElementType.WAITING:
          return "В ожидании ответа";
        default:
          return "Сформировано всего";
      }
    },
    wrapperClass(): (string | Record<string, boolean>)[] {
      return [
        "info-element pt-4 pl-4 pb-0 pr-2 d-flex justify-space-between",
        {
          "info-element_error": this.type === InfoElementType.ERROR,
          "info-element_success": this.type === InfoElementType.SUCCESS,
          "info-element_waiting": this.type === InfoElementType.WAITING,
          "info-element_default": this.type === InfoElementType.DEFAULT,
        },
      ];
    },
    activeStyleObject() {
      if (this.isActive) {
        return {
          border: `1px solid rgb(${this.graphBorderColor})`,
        };
      }
    },
  },
  methods: {
    ...mapActions("syncLogOikView", ["setSuccessGraphData"]),
    clickElementCb() {
      this.$emit("click");
    },
  },
});
