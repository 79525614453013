import moment from "moment";
import { fields } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/fields";
import { ISyncHistoryItem, ISyncLogElement } from "@monorepo/monitoring/src/views/SyncLogOik/types/syncLogElement";
import { TK_STATUS } from "@monorepo/utils/src/types/tkStatus";
import { convertStatusesToTitle } from "@monorepo/utils/src/utils/converOIKStatusesToTitle";

const maxStep = 4;

const getStatus = (item: ISyncLogElement) => {
  const logs = item?.transportContainer?.dictionaryContainerLogs || [];
  let steps = logs.length > 0 ? 1 : undefined;
  let status = TK_STATUS.PENDING;
  let description = "";

  logs.some((log, index) => {
    if (log.result === false) {
      steps = index + 1;
      status = TK_STATUS.ERROR;
      description = log.description;
      return true;
    }

    if (log.result === true && maxStep === index + 1) {
      steps = index + 1;
      status = TK_STATUS.SUCCESS;
      description = log.description;
      return true;
    }

    steps = index + 1;
    return false;
  });

  return {
    steps,
    description,
    status,
  };
};

export const convertDataItemToTableView = (item: ISyncLogElement): ISyncLogElement => {
  return {
    ...item,
    [fields.SUCCESS_SYNC]: item.successExportDate ? convertDateItem(item.successExportDate as string, "YYYY-MM-DD HH:mm") : "",
    [fields.LAST_SYNC]: item.lastTryExportDate ? convertDateItem(item.lastTryExportDate as string, "YYYY-MM-DD HH:mm") : "",
    [fields.OIK_NAME]: item.oikName || "",
    [fields.OIK_CODE]: item.oikCode || "",
    //[fields.SYNC_HISTORY]: item[fields.SYNC_HISTORY as keyof ISyncLogElement],
    [fields.SYNC_HISTORY]: item[fields.SYNC_HISTORY as keyof ISyncLogElement]
      ? (item[fields.SYNC_HISTORY as keyof ISyncLogElement] as ISyncHistoryItem[]).map((historyItem: ISyncHistoryItem) => {
          return {
            ...historyItem,
            date: historyItem.date ? convertDateItem(historyItem.date, "YYYY-MM-DD HH:mm") : "",
          };
        })
      : [],
    [fields.STATUS_RUSSIAN_TEXT]: convertStatusesToTitle(item?.transportContainer?.status),
    [fields.TEXT_MESSAGE]: item?.dictionaryResponse?.message ?? "-",
    ...getStatus(item),
  };
};

const convertDateItem = (item: string | number, format = "YYYY-MM-DD"): string => {
  return moment(item.toString()).format(format);
};
