export const getStatuses = (status: string): string[] => {
  switch (status) {
    case "DEFAULT":
      return ["SUCCESSFULLY_UPDATED", "SENT_TO_OIK", "OIK_ERROR", "TIMEOUT_FOR_OIK_RESPONSE", "ERROR", "TRANSFER_HDUO"];
    case "SUCCESSFULLY_UPDATED":
      return ["SUCCESSFULLY_UPDATED"];
    case "SENT_TO_OIK":
      return ["SENT_TO_OIK"];
    case "ERROR":
      return ["OIK_ERROR", "TIMEOUT_FOR_OIK_RESPONSE", "ERROR"];
    default:
      return [];
  }
};
