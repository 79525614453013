import { fields } from "./fields";

export const filtersElements = [
  {
    value: fields.FILTER_OIK_CODE,
    title: "Код ОИК",
  },
  {
    value: fields.STATUS,
    title: "Статус",
  },
  {
    value: fields.DATE,
    title: "Период",
  },
];
